const menus = [
    {
        id: 1,
        tomenu: "#home",
        namemenu: "หน้าแรก"
    },
    {
        id: 2,
        tomenu: "#services",
        namemenu: "ทำไมต้อง AAppoint"
    },
    // {
    //     id: 3,
    //     tomenu: "#reserve",
    //     namemenu: "จองโต๊ะ"
    // },
    {
        id: 4,
        tomenu: "#news",
        namemenu: "ข่าวสาร"
    },
    {
        id: 5,
        tomenu: "#pricing",
        namemenu: "ราคา"
    },
    {
        id: 6,
        tomenu: "#contact",
        namemenu: "ติดต่อเรา"
    },
    
];



export default menus ;